.mapboxgl-popup-content,
.mapboxgl-ctrl-group {
  border-radius: 0 !important;
}

.mapboxgl-popup-content {
  padding: 20px 20px 25px;
}

.mapboxgl-popup-close-button {
  padding: 4px 8px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 18px;
}

.mapboxgl-popup-close-button:hover {
    background-color: transparent !important;
}

.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl-logo {
  opacity: 1;
}
