.root {
    display: flex;
    height: 100vh;
}

.fullCenter {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
}
