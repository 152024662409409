@font-face {
    font-family: 'LandsecBrown-Regular';
    src: url('/fonts/LandsecBrown-Regular.woff') format('woff'),
         url('/fonts/LandsecBrown-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LandsecBrown-Bold';
    src: url('/fonts/LandsecBrown-Bold.woff') format('woff'),
         url('/fonts/LandsecBrown-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
